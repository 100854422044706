var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('reviews.title'),"sub-title":_vm.$t('reviews.desc')}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.content},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderCoupons}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 px-2"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[(_vm.getAllCoupons)?_c('w-tables',{attrs:{"per-page":_vm.filterData.per_page,"items":_vm.getAllCoupons,"fields":_vm.fields,"status":_vm.accountStatus,"custimized-items":[
              { name: 'name' },
              { name: 'rate' },
              { name: 'action' } ]},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-center align-items-center"},[_c('b-avatar',{attrs:{"src":data.item.image}}),_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(data.item.name))])],1)]}},{key:"rate",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"rate"},[_c('symbol',{attrs:{"id":"icon-star","view-box":"0 0 12 12"}},[_c('path',{attrs:{"id":"Path_147238","data-name":"Path 147238","d":"M13.649,7.759a1.271,1.271,0,0,0,.294-1.3A1.255,1.255,0,0,0,12.928,5.6l-2.576-.4a.084.084,0,0,1-.054-.048l-1.129-2.4a1.268,1.268,0,0,0-2.312-.018l-1.129,2.4a.1.1,0,0,1-.06.048L3.1,5.6a1.273,1.273,0,0,0-1.021.858,1.289,1.289,0,0,0,.3,1.3l1.9,1.95a.1.1,0,0,1,0,.078L3.83,12.51a1.273,1.273,0,0,0,1.88,1.326l2.24-1.242a.078.078,0,0,1,.072,0l2.24,1.242a1.279,1.279,0,0,0,1.88-1.326L11.727,9.8a.072.072,0,0,1,0-.072Z","transform":"translate(-2.012 -2)"}})]),_c('rate',{staticClass:"RateCustom",attrs:{"length":5,"value":data.item.rate,"readonly":true,"iconref":"icon-star"}})],1)]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('b-dropdown',{attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-14","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon',{staticClass:"mx-3 my-1"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"link-class":"py-0min-height: 52px;"},on:{"click":function($event){return _vm.reviewDetail(data.item.id)}}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('eyeon-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.show_details'))+" ")],1)])],1)]}}],null,false,1443359040)}):_vm._e(),(_vm.getAllCoupons && _vm.getAllCoupons.length < 1)?_c('wameed-no-data',{attrs:{"icon":"nodata-icon","title":_vm.$t('packages.no_data'),"sub-title":_vm.$t('packages.no_data_text')}}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotalCoupons)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotalCoupons.totalItems,"per_page":_vm.filterData.per_page},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }