<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('reviews.title')"
        :sub-title="$t('reviews.desc')"
    />

    <div>
      <filter-header
          :content="content"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderCoupons"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
                v-if="getAllCoupons"
                :per-page="filterData.per_page"
                :items="getAllCoupons"
                :fields="fields"
                :status="accountStatus"
                :custimized-items="[
                { name: 'name' },
                { name: 'rate' },
                { name: 'action' },

              ]"
            >
              <template slot="name" slot-scope="{ data }">
                <div class="d-flex justify-center align-items-center">
                  <b-avatar :src="data.item.image" />
                  <span class="text-font-main mx-3">{{ data.item.name }}</span>
                </div>
              </template>
              <template slot="rate" slot-scope="{ data }">
                <div class="rate">
                  <symbol id="icon-star" view-box="0 0 12 12">
                    <path
                        id="Path_147238"
                        data-name="Path 147238"
                        d="M13.649,7.759a1.271,1.271,0,0,0,.294-1.3A1.255,1.255,0,0,0,12.928,5.6l-2.576-.4a.084.084,0,0,1-.054-.048l-1.129-2.4a1.268,1.268,0,0,0-2.312-.018l-1.129,2.4a.1.1,0,0,1-.06.048L3.1,5.6a1.273,1.273,0,0,0-1.021.858,1.289,1.289,0,0,0,.3,1.3l1.9,1.95a.1.1,0,0,1,0,.078L3.83,12.51a1.273,1.273,0,0,0,1.88,1.326l2.24-1.242a.078.078,0,0,1,.072,0l2.24,1.242a1.279,1.279,0,0,0,1.88-1.326L11.727,9.8a.072.072,0,0,1,0-.072Z"
                        transform="translate(-2.012 -2)"
                    />
                  </symbol>
                <rate
                    class="RateCustom"
                    :length="5"
                    :value="data.item.rate"
                    :readonly="true"
                    iconref="icon-star"
                />
                </div>
              </template>

              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-14"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1"/>
                  </template>
                  <b-dropdown-item
                      link-class="py-0min-height: 52px;"
                      @click="reviewDetail(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
                v-if="getAllCoupons && getAllCoupons.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotalCoupons"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalCoupons.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';

import rate from 'vue-rate/src/Rate.vue';
export default {
  components: {
    rate,
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        status: '',
        rate:'',
        subscription:'',
        type:'',
        page: 1,
        per_page: 20,
        order_by: 'name',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          data: [
            {
              id: 'active',
              title: 'active',
            },
            {
              id: 'not_active',
              title: 'not_active',
            },
          ],
        },
        {
          title: 'provider_type',
          key: 'type',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },
        {
          title: 'subscription_type',
          key: 'subscription',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },
        {
          title: 'rate',
          key: 'rate',
          id: 'id',
          name: 'title',
          selectedData: [],
          data: [
            {
              id: '5',
              title: '5',
            },
            {
              id: '4',
              title: '4',
            },
            {
              id: '3',
              title: '3',
            },
            {
              id: '2',
              title: '2',
            },
            {
              id: '1',
              title: '1',
            },
          ],
        },
      ],
      fields: [
        {
          key: 'name',
          label: this.$i18n.t('table.user_name'),
          sortable: true,
        },
        {
          key: 'account_type',
          label: this.$i18n.t('table.type'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$i18n.t('table.email'),
          sortable: true,
        },
        {
          key: 'subscription_type',
          label: this.$i18n.t('table.subscription_type'),
          sortable: true,
        },
        {
          key: 'rate',
          label: this.$i18n.t('table.rate'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('table.subscription_status'),
          sortable: true,
        },
        {key: 'action', label: ''},
      ],
      accountStatus: [
        {
          active: 'active',
          not_active: 'not_active',

        },
        {
          active: 'success',
          not_active: 'input',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllCoupons: 'getAllReviewsProviders',
      getTotalCoupons: 'getTotalReviewsProviders',
      getFilterData: 'getFilterData',
    }),
  },
  watch: {
    getFilterData(newVal) {
      if (newVal) {
        this.content[2].data = newVal.subscriptions ? newVal.subscriptions : [];
        this.content[1].data = newVal.types ? newVal.types : [];
      }
    },
  },
  created() {
    this.loadData();
    this.loadFilterData('reviews_providers');
  },
  methods: {
    ...mapActions({
      loadReviewsProviders: 'loadReviewsProviders',
      loadFilterData: 'loadFilterData',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadReviewsProviders(this.filterData);
    },
    reviewDetail(id) {
      this.$router.push({
        name: 'reviewDetail',
        params: {lang: this.$i18n.locale,id: id},
      });
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderCoupons(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
